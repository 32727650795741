<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div v-if="!eventLoaded" class="sector pl-0 pr-0">
                            <Spinner size="medium" line-fg-color="#e91e63" />
                        </div>
                        <div v-else class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Edit event</h2>
                            <div class="profile__content">
                                <p v-if="updateEventForm.localError" class="custom-error mb-3">{{ updateEventForm.localError }}</p>
                                <p v-if="getEventError" class="custom-error mb-3"> {{ getEventError }} </p>
                                <form :class="getDisabledEvent ? 'disabled-element' : ''" @submit.prevent="updateEvent">
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="profile__label float-right">*Required fields</span>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Event name*</label>
                                                <input :disabled="getDisabledEvent" @input="$v.updateEventForm.eventName.$touch()" v-model.trim="updateEventForm.eventName" :class="[{'input-error' : $v.updateEventForm.eventName.$error }, getDisabledEvent ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type event name" />
                                                <div v-if="$v.updateEventForm.eventName.$error">
                                                    <p v-if="!$v.updateEventForm.eventName.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateEventForm.eventName.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">E-mail address</label>
                                                <input :disabled="getDisabledEvent"  @input="$v.updateEventForm.eventContactEmail.$touch()" :class="[{'input-error' : $v.updateEventForm.eventContactEmail.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventContactEmail" class="form-input mb-1" placeholder="Type contact e-mail" type="email" />
                                                <div v-if="$v.updateEventForm.eventContactEmail.$error">
                                                    <p v-if="!$v.updateEventForm.eventContactEmail.email" class="custom-error">E-mail address is invalid</p>
                                                    <p v-else-if="!$v.updateEventForm.eventContactEmail.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Event website URL<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />With http or https protocol</span></label>
                                                <input :disabled="getDisabledEvent"  @input="$v.updateEventForm.eventContactUrl.$touch()" :class="[{'input-error' : $v.updateEventForm.eventContactUrl.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventContactUrl" class="form-input mb-1" placeholder="Type event website URL" />
                                                <div v-if="$v.updateEventForm.eventContactUrl.$error">
                                                    <p v-if="!$v.updateEventForm.eventContactUrl.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                    <p v-else-if="!$v.updateEventForm.eventContactUrl.urlCustomValidator" class="custom-error">This field must start with either http:// or https://</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">City*</label>
                                                <input :disabled="getDisabledEvent"  @input="$v.updateEventForm.eventCity.$touch()" :class="[{'input-error' : $v.updateEventForm.eventCity.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventCity" class="form-input mb-1" placeholder="Type city" />
                                                <div v-if="$v.updateEventForm.eventCity.$error">
                                                    <p v-if="!$v.updateEventForm.eventCity.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateEventForm.eventCity.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Event date*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />day, month, year</span></label>
                                                <date-picker :disabled="getDisabledEvent" :class="[{'input-error' : $v.updateEventForm.eventDate.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventDate" class="mb-1"  lang="en" format="DD-MM-YYYY" placeholder="Select event date"></date-picker>
                                                <div v-if="$v.updateEventForm.eventDate.$error">
                                                    <p v-if="!$v.updateEventForm.eventDate.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateEventForm.eventDate.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Event type*</label>
                                                <v-select :disabled="getDisabledEvent" @input="$v.updateEventForm.eventType.$touch()" :class="[{'input-error' : $v.updateEventForm.eventType.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventType" class="custom-sorter mb-1" labelTitle = "Select event type" :options="eventTypes" />
                                                <div v-if="$v.updateEventForm.eventType.$error">
                                                    <p v-if="!$v.updateEventForm.eventType.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateEventForm.eventType.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Phone number</label>
                                                <input :disabled="getDisabledEvent" @input="$v.updateEventForm.eventContactPhone.$touch()" :class="[{'input-error' : $v.updateEventForm.eventContactPhone.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventContactPhone" class="form-input mb-1" placeholder="Type contact phone number" />
                                                <div v-if="$v.updateEventForm.eventContactPhone.$error"> <p v-if="!$v.updateEventForm.eventContactPhone.maxLength" class="custom-error">This field must not exceed 20 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Place address*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Street name and street number</span></label>
                                                <input :disabled="getDisabledEvent" @input="$v.updateEventForm.eventAddress.$touch()" :class="[{'input-error' : $v.updateEventForm.eventAddress.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventAddress" class="form-input mb-1" placeholder="Type place address" />
                                                <div v-if="$v.updateEventForm.eventAddress.$error">
                                                    <p v-if="!$v.updateEventForm.eventAddress.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateEventForm.eventAddress.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Country*</label>
                                                <v-select :disabled="getDisabledEvent" @input="$v.updateEventForm.eventCountry.$touch()" :class="[{'input-error' : $v.updateEventForm.eventCountry.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventCountry" class="custom-sorter mb-1" labelTitle = "Select country" :options="countries" :searchable = true />
                                                <div v-if="$v.updateEventForm.eventCountry.$error">
                                                    <p v-if="!$v.updateEventForm.eventCountry.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateEventForm.eventCountry.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Event description*</label>
                                                <vue-editor :disabled="getDisabledEvent"  @input="$v.updateEventForm.eventDescription.$touch()" :class="[{'input-error' : $v.updateEventForm.eventDescription.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model.trim="updateEventForm.eventDescription" :editorToolbar="customToolbar"></vue-editor>
                                                <div v-if="$v.updateEventForm.eventDescription.$error">
                                                    <p v-if="!$v.updateEventForm.eventDescription.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateEventForm.eventDescription.maxLength" class="custom-error">This field must not exceed 3000 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Location* <span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Move the blue marker on the map and specify the location</span></label>
                                                <div class="profile__map">
                                                    <l-map :zoom="zoom" :center="center" :options="{zoomControl: false}">
                                                        <l-tile-layer
                                                        :url="url"
                                                        layer-type="base"></l-tile-layer>
                                                        <l-marker :lat-lng="updateEventForm.eventMarker" :draggable="true" @update:latLng="positionUpdate"></l-marker>
                                                        <l-control-zoom position="topright"></l-control-zoom>
                                                    </l-map>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Latitude</label>
                                                <input readonly :disabled="getDisabledEvent" @input="$v.updateEventForm.eventMarker.lat.$touch()" :class="[{'input-error' : $v.updateEventForm.eventMarker.lat.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model="updateEventForm.eventMarker.lat" class="form-input mb-1" />
                                                <div v-if="$v.updateEventForm.eventMarker.lat.$error">
                                                    <p v-if="!$v.updateEventForm.eventMarker.lat.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Longitude</label>
                                                <input readonly :disabled="getDisabledEvent" @input="$v.updateEventForm.eventMarker.lng.$touch()" :class="[{'input-error' : $v.updateEventForm.eventMarker.lng.$error }, getDisabledEvent ? 'disabled-element' : '' ]" v-model="updateEventForm.eventMarker.lng" class="form-input mb-1" />
                                                <div v-if="$v.updateEventForm.eventMarker.lng.$error">
                                                    <p v-if="!$v.updateEventForm.eventMarker.lng.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Event banner*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Recommended size: 700px by 430px, JPG, PNG, max 5MB</span></label>
                                                <b-row>
                                                    <b-col cols="12">
                                                        <div :style="{ backgroundImage: 'url(' + updateEventForm.currentImage + ')' }" id="eventImage" class="event-details__image"></div>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <div class="custom-upload mt-3">
                                                            <label for="fileUpload" class="custom-upload-label">
                                                                <simple-line-icons icon="picture" size="small" color="#504e70" />Choose file
                                                            </label>
                                                            <input :class="getDisabledEvent ? 'disabled-element' : ''" @change="onFileChanged" id="fileUpload" type="file"/>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <button :disabled="getDisabledEvent" type="submit" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Update event</button>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import VSelect from '../assets/files/customSelect.esm.js'
import PrettyCheck from 'pretty-checkbox-vue/check'
import DatePicker from 'vue2-datepicker'
import { VueEditor, Quill } from 'vue2-editor'
import countriesList from '../assets/files/countriesList.js'
import eventTypesList from '../assets/files/eventTypesList.js'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import moment from 'moment'
import firebase from 'firebase/app'
import router from '../router/router'
import {
  email,
  required,
  maxLength
} from 'vuelidate/lib/validators'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LControlZoom
} from 'vue2-leaflet'

function urlCustomValidator (value) {
  if (value !== '') {
    var pattern = /^((http|https):\/\/)/

    if (!pattern.test(value)) {
      return false
    }
  }
  return true
}

export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    VSelect,
    'p-check': PrettyCheck,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControlZoom,
    DatePicker,
    VueEditor,
    Spinner
  },
  data: function () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      center: null,
      customToolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
      ],
      eventTypes: [
        { value: 'a', text: 'Type 1' },
        { value: 'b', text: 'Type 2' },
        { value: 'c', text: 'Type 3' },
        { value: 'd', text: 'Type 4' }
      ],
      countries: [],
      eventLoaded: false,
      updateEventForm: {
        eventName: null,
        eventType: null,
        eventContactEmail: '',
        eventContactPhone: '',
        eventContactUrl: '',
        eventAddress: null,
        eventCity: null,
        eventCountry: null,
        eventDate: null,
        eventDescription: null,
        eventMarker: {
          lat: 52.163308, lng: 21.084495
        },
        currentImage: null,
        imageName: null,
        selectedFile: null,
        localError: null,
        eventId: null
      }
    }
  },
  validations: {
    updateEventForm: {
      eventName: {
        required: required,
        maxLength: maxLength(200)
      },
      eventType: {
        required: required,
        maxLength: maxLength(200)
      },
      eventContactEmail: {
        email: email,
        maxLength: maxLength(200)
      },
      eventContactPhone: {
        maxLength: maxLength(20)
      },
      eventContactUrl: {
        maxLength: maxLength(200),
        urlCustomValidator
      },
      eventAddress: {
        required: required,
        maxLength: maxLength(200)
      },
      eventCity: {
        required: required,
        maxLength: maxLength(200)
      },
      eventCountry: {
        required: required,
        maxLength: maxLength(200)
      },
      eventDate: {
        required: required,
        maxLength: maxLength(200)
      },
      eventDescription: {
        required: required,
        maxLength: maxLength(3000)
      },
      eventMarker: {
        lat: {
          required: required
        },
        lng: {
          required: required
        }
      }
    }
  },
  methods: {
    positionUpdate (latLng) {
      this.updateEventForm.eventMarker = latLng
    },
    updateEvent () {
      this.$store.dispatch('setDisabledEvent')
      this.$v.updateEventForm.$touch()
      if (this.updateEventForm.selectedFile !== null) {
        if (!this.$v.updateEventForm.$invalid) {
          this.$store.dispatch('updateEventWithImage', { name: this.updateEventForm.eventName, type: this.updateEventForm.eventType, email: this.updateEventForm.eventContactEmail, phone: this.updateEventForm.eventContactPhone, website: this.updateEventForm.eventContactUrl, address: this.updateEventForm.eventAddress, city: this.updateEventForm.eventCity, country: this.updateEventForm.eventCountry, date: this.updateEventForm.eventDate, desc: this.updateEventForm.eventDescription, lat: this.updateEventForm.eventMarker.lat, lng: this.updateEventForm.eventMarker.lng, image: this.updateEventForm.selectedFile, imageName: this.updateEventForm.imageName, eventId: this.updateEventForm.eventId })
            .then(() => {
              this.updateEventForm.localError = ''
              this.scrollToTop(500)
            })
            .catch(error => {
              this.updateEventForm.localError = error.message
              this.$store.dispatch('clearDisabledEvent')
            })
        } else {
          this.updateEventForm.localError = 'Invalid form'
          this.$store.dispatch('clearDisabledEvent')
          this.scrollToTop(500)
        }
      } else {
        if (!this.$v.updateEventForm.$invalid) {
          this.$store.dispatch('updateEventWithoutImage', { name: this.updateEventForm.eventName, type: this.updateEventForm.eventType, email: this.updateEventForm.eventContactEmail, phone: this.updateEventForm.eventContactPhone, website: this.updateEventForm.eventContactUrl, address: this.updateEventForm.eventAddress, city: this.updateEventForm.eventCity, country: this.updateEventForm.eventCountry, date: this.updateEventForm.eventDate, desc: this.updateEventForm.eventDescription, lat: this.updateEventForm.eventMarker.lat, lng: this.updateEventForm.eventMarker.lng, eventId: this.updateEventForm.eventId })
            .then(() => {
              this.updateEventForm.localError = ''
              this.scrollToTop(500)
            })
            .catch(error => {
              this.updateEventForm.localError = error.message
              this.$store.dispatch('clearDisabledEvent')
            })
        } else {
          this.updateEventForm.localError = 'Invalid form'
          this.$store.dispatch('clearDisabledEvent')
          this.scrollToTop(500)
        }
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    },
    onFileChanged (event) {
      if (event.target.files[0] != undefined) {
        this.updateEventForm.selectedFile = event.target.files[0]

        var inputFile = document.querySelector('#fileUpload')

        var selectedValue = document.querySelector('#fileUpload').value

        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

        var fileSize = this.updateEventForm.selectedFile.size / 1024 / 1024 // in MB

        if (!allowedExtensions.exec(selectedValue)) {
          this.$swal({
            type: 'error',
            title: 'Invalid file extension',
            text: 'Supported file formats: .jpeg .jpg .png .gif',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.updateEventForm.selectedFile = null

          return false
        } else if (fileSize > 5) {
          this.$swal({
            type: 'error',
            title: 'File size is too large',
            text: 'Uploaded file size must not exceed 5MB',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.updateEventForm.selectedFile = null

          return false
        } else {
          // Image preview
          if (this.updateEventForm.selectedFile) {
            var reader = new FileReader()
            reader.onload = function (e) {
              document.getElementById('eventImage').style.backgroundImage = 'url("' + e.target.result + '")'
            }
            reader.readAsDataURL(inputFile.files[0])
          }
        }
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledEvent')
    this.$store.dispatch('getUserEvents')
  },
  created () {
    this.countries = countriesList
    this.eventTypes = eventTypesList

    var user = firebase.auth().currentUser

    var eventsRef = firebase.database().ref('events')

    eventsRef.child(this.$route.params.id).on('value', snapshot => {
      (this.updateEventForm.eventId = snapshot.key),
      (this.updateEventForm.eventName = snapshot.val().name),
      (this.updateEventForm.eventType = snapshot.val().type),
      (this.updateEventForm.eventContactPhone = snapshot.val().phone),
      (this.updateEventForm.eventContactUrl = snapshot.val().website),
      (this.updateEventForm.eventAddress = snapshot.val().address),
      (this.updateEventForm.eventCity = snapshot.val().city),
      (this.updateEventForm.eventCountry = snapshot.val().country),
      (this.updateEventForm.eventDate = new Date(moment(snapshot.val().date, 'DD-MM-YYYY'))),
      (this.updateEventForm.eventDescription = snapshot.val().desc),
      (this.updateEventForm.eventMarker.lat = snapshot.val().lat),
      (this.updateEventForm.eventMarker.lng = snapshot.val().lng),
      (this.center = [snapshot.val().lat, snapshot.val().lng]),
      (this.updateEventForm.currentImage = snapshot.val().url + '/?v' + Math.floor((Math.random() * 100) + 1)),
      (this.updateEventForm.imageName = snapshot.val().image)

      if (snapshot.val().user != user.uid) {
        router.push('/401')
      }

      this.eventLoaded = true
    })
  },
  computed: {
    ...mapGetters([
      'getEventError',
      'getDisabledEvent'
    ])
  }
}
</script>
